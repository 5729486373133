import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CometChat } from "@cometchat-pro/chat";
import { APP_ID, REGION } from "./config";
import { CometChatCalls } from "@cometchat/calls-sdk-javascript";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const appSetting: CometChat.AppSettings = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(REGION)
  .autoEstablishSocketConnection(true)
  // .overrideAdminHost(APP_ID + ".api-" + REGION + ".cometchat-staging.com/v3.0")
  // .overrideClientHost(APP_ID + ".apiclient-" + REGION + ".cometchat-staging.com/v3.0")
  .build();
CometChat.init(APP_ID, appSetting).then(
  (initialized: boolean) => {
    console.log("Initialization completed successfully", initialized);
  },
  (error: CometChat.CometChatException) => {
    console.log("Initialization failed with error:", error);
  }
);

const callAppSetting = new CometChatCalls.CallAppSettingsBuilder()
  .setAppId(APP_ID)
  .setRegion(REGION)
  // .setHost("https://" + APP_ID + ".call-" + REGION + ".cometchat-staging.com/v3.0/")
  .build();

CometChatCalls.init(callAppSetting).then(
  () => {
    console.log("CometChatCalls initialization completed successfully");
  },
  (error) => {
    console.log("CometChatCalls initialization failed with error:", error);
  }
);